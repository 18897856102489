<template>
    <!--Light Box-->
    <div class="root" v-if="which">
        <!--First Run-->
        <div v-if="isIntro" class="intro">
            <h2>Thank you for installing {{ branding.ProductName }}</h2>
            <div v-if="firstRunQuery && firstRunCount">
                <h1>
                    Find over {{ firstRunCount }} <br /><span>{{
                        firstRunQuery
                    }}</span>
                    recipes<br />ready to cook!
                </h1>
            </div>
            <div v-else>
                <h1>
                    Find delicious recipes<br />
                    <span>ready to cook on {{ branding.ProductName }}</span> or
                    <span>search for more new recipes</span><br />
                    over the web.
                </h1>
            </div>
            <p>Scroll down to start browsing recipes</p>
        </div>
        <!-- End of First Run-->
        <!--Tips-->
        <div v-else-if="isTutorial" class="intro">
            <h1>
                Getting the most out of<br />
                {{ branding.ProductName }}
            </h1>
            <img :src="branding.SettingsGif" class="type" />
            <ol>
                <li>
                    Go to the address bar and type
                    <strong>"{{ branding.MetaSearchKeyword }}"</strong>
                </li>
                <li>
                    After
                    <strong>"{{ branding.MetaSearchKeyword }}"</strong> hit
                    spacebar then type your search.<br />
                    (e.g. <strong>{{ branding.SearchExample }}</strong
                    >)
                </li>
            </ol>
        </div>
        <!-- End Tips-->
    </div>
    <!--End of light box-->
</template>
<script>
import { arrow } from "@tightrope/newtab";
import { getBranding } from "../branding";

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

export default {
    name: "first-run",
    props: [
        "which",
        "onClose",
        "onNext",
        "q",
        "firstRunQuery",
        "firstRunCount",
        "doProductSearch",
    ],
    data() {
        return {
            branding: getBranding(),
        };
    },
    mounted() {
        arrow("newtab");
    },
    methods: {
        canShow(name) {
            return this.which && this.which.endsWith(name);
        },
        pickLearnTips() {
            switch (this.which) {
                case "intro":
                    this.onNext("it");
            }
        },
        onCloseClick() {
            this.onClose && this.onClose(...arguments);
        },
        async startProductSearch() {
            if (this.query) {
                this.doProductSearch();
            } else {
                this.onCloseClick();
            }
        },
    },
    computed: {
        isTutorial() {
            return this.canShow("tutorial");
        },
        isIntro() {
            return this.canShow("intro");
        },
    },
};
</script>
