import { getBranding } from "./branding";
import { sendMessageToExt } from "./storage";
import { isFirefox } from "./common";

const branding = getBranding();

const TOP_DOMAIN =
    (typeof branding !== "undefined" && branding.RootDomain) ||
    (function() {
        const parts = window.location.hostname.split(/\./);
        const domain =
            parts.length == 1
                ? window.location.hostname
                : parts.slice(-2).join(".");
        return (
            domain + (window.location.port ? `:${window.location.port}` : "")
        ).toLowerCase();
    })();

const CURRENT_HOST = `${new URL(window.location).origin}`;
const HOME_SERP_PATH = "/public/home.html";

const API_ROUTE = "/api";

const WEB_SEARCH_URL = `https://services.${TOP_DOMAIN}/crx/search.php`;
const SEARCH_API_URL = `${API_ROUTE}/v1/search`;
const SEARCH_API_INDEXER_URL = `${API_ROUTE}/indexer`;
const API_INDEXER_GROUP = parseInt(process.env.API_INDEXER_GROUP) || 10;
const FRESHY_RECIPE_URL = "https://cooking.freshy.com/recipe";
const FRESHY_LINK_TRACKING = { utm_medium: "serp" };

function openUrl(toOpen, newTab = true, msg = true) {
    if (!newTab) {
        return window.open(
            toOpen,
            window.self !== window.top ? "_parent" : "_self"
        );
    }
    if (isFirefox && msg) {
        return sendMessageToExt({
            task: "sendMessage",
            msg: "openUrl",
            obj: { url: toOpen },
        });
    }
    window.open(toOpen, "_blank");
}
export {
    WEB_SEARCH_URL,
    SEARCH_API_URL,
    SEARCH_API_INDEXER_URL,
    API_INDEXER_GROUP,
    FRESHY_RECIPE_URL,
    FRESHY_LINK_TRACKING,
    openUrl,
    TOP_DOMAIN,
    CURRENT_HOST,
    HOME_SERP_PATH,
};
