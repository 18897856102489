const MENUS = [
    {
        name: "Ingredients",
        items: [
            { value: "Beans", model: "resultCategory1" },
            { value: "Cheese", model: "resultCategory1" },
            { value: "Chicken", model: "resultCategory1" },
            { value: "Chicken Breast", model: "resultCategory1" },
            { value: "Crab", model: "resultCategory1" },
            { value: "Fruit", model: "resultCategory1" },
            { value: "Grains", model: "resultCategory1" },
            { value: "Lamb/Sheep", model: "resultCategory1" },
            { value: "Meat", model: "resultCategory1" },
            { value: "Onions", model: "resultCategory1" },
            { value: "Pork", model: "resultCategory1" },
            { value: "Potato", model: "resultCategory1" },
            { value: "Poultry", model: "resultCategory1" },
            { value: "Vegetable", model: "resultCategory1" },
        ],
    },
    {
        name: "Meal / Course",
        items: [
            { value: "Breakfast", model: "resultCategory1" },
            { value: "Dessert", model: "resultCategory1" },
            { value: "dinner", model: "resultCategory1" },
            { value: "Lunch/Snacks", model: "resultCategory1" },
        ],
    },
    {
        name: "Dish type",
        items: [
            { value: "Bar Cookie", model: "resultCategory1" },
            { value: "Beverages", model: "resultCategory1" },
            { value: "Breads", model: "resultCategory1" },
            { value: "Candy", model: "resultCategory1" },
            { value: "Cheesecake", model: "resultCategory1" },
            { value: "Chowders", model: "resultCategory1" },
            { value: "Curries", model: "resultCategory1" },
            { value: "Drop Cookies", model: "resultCategory1" },
            { value: "Frozen Desserts", model: "resultCategory1" },
            { value: "One Dish Meal", model: "resultCategory1" },
            { value: "Pie", model: "resultCategory1" },
            { value: "Punch Beverage", model: "resultCategory1" },
            { value: "Quick Breads", model: "resultCategory1" },
            { value: "Rice", model: "resultCategory1" },
            { value: "Salad Dressings", model: "resultCategory1" },
            { value: "Sauces", model: "resultCategory1" },
            { value: "Savory Pies", model: "resultCategory1" },
            { value: "Smoothies", model: "resultCategory1" },
            { value: "Spreads", model: "resultCategory1" },
            { value: "Steak", model: "resultCategory1" },
            { value: "Stew", model: "resultCategory1" },
            { value: "Yeast Breads", model: "resultCategory1" },
        ],
    },
    {
        name: "Cuisine",
        items: [
            { value: "Asian", model: "resultCategory1" },
            { value: "European", model: "resultCategory1" },
            { value: "kids", model: "resultCategory1" },
        ],
    },
];

function getIsoDurationInSeconds(isoDuration) {
    const duration = isoDurationParser(isoDuration);
    if (duration) {
        const hourInSec = 60 * 60 * duration.hour;
        const minInSec = 60 * duration.min;
        return hourInSec + minInSec + duration.sec;
    }
    return null;
}

function isoDurationParser(isoDuration) {
    const getNumberComponent = function(str) {
        const validCheck = /[0-9]*[A-Z]$/;
        const numberOnly = /[0-9]*/;
        if (numberOnly.test(str)) {
            const numStr = numberOnly.exec(str)[0];
            const output = parseInt(numStr);
            return output;
        }
        return null;
    };

    const validIsoDuration = /^PT([0-9]*[H,M,S])+$/;

    const hourCheck = /[0-9]*H/;
    const minCheck = /[0-9]*M/;
    const secCheck = /[0-9]*S/;

    if (validIsoDuration.test(isoDuration)) {
        const output = { hour: null, min: null, sec: null };

        if (hourCheck.test(isoDuration))
            output.hour = getNumberComponent(hourCheck.exec(isoDuration)[0]);
        if (minCheck.test(isoDuration))
            output.min = getNumberComponent(minCheck.exec(isoDuration)[0]);
        if (secCheck.test(isoDuration))
            output.sec = getNumberComponent(secCheck.exec(isoDuration)[0]);

        return output;
    }
    return null;
}

function getFormattedIsoDuration(isoDuration, prefixStr = null) {
    const duration = isoDurationParser(isoDuration);
    if (duration) {
        let output = "";
        let hasLength = false;
        if (prefixStr) output += prefixStr;
        if (duration.hour) {
            output += `${duration.hour}h`;
            hasLength = true;
        }
        if (duration.min) {
            output += `${duration.min}m`;
            hasLength = true;
        }
        if (duration.sec) {
            hasLength = true;
            output += `${duration.sec}s`;
        }
        if (hasLength) return output;
        return "";
    }
    return "";
}

export const FILTER_IMAGE_URLS = [
    "https://img.sndimg.com/food/image/upload/q_92,fl_progressive,w_1200,c_scale/v1/gk-static/fdc-new/img/fdc-shareGraphic.png",
    "https://assets.myfoodandfamily.com/adaptivemedia/rendition/56790_#100664957.tif?id=183c2482a6f8ad3995f366eee9aa3a589294ef34&ht=400&wd=600&version=1&clid=pim",
    "https://d167y3o4ydtmfg.cloudfront.net/kraftrecipes/v201909042247/img/noImage_1366x616_RecipeMain.png",
    "https://images.media-allrecipes.com/images/79591.png",
    "https://www.epicurious.com/static/img/misc/epicurious-social-logo.png",
    "https://chowhound1.cbsistatic.com/s/og_image_large-ada5e42f1741497fce4c63c5025792f9.jpg",
    "https://copykat.com/wp-content/uploads/2018/06/image-coming-soon.jpg",
];

export default {
    MENUS,
    getIsoDurationInSeconds,
    getFormattedIsoDuration,
};
