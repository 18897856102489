/**
 * Common functions and state management
 */

export const documentReady = new Promise(resolve => {
    if (document.readyState === "complete") {
        resolve(true);
        return;
    }
    document.addEventListener("DOMContentLoaded", () => {
        resolve(true);
    });
});

export const isFirefox = navigator.userAgent.includes("Firefox");

export const WEB_SEARCH_PROVIDERS = {
    yahoo: {
        name: "Yahoo",
        id: "yahoo",
        url: "",
    },
    google: {
        name: "Google",
        id: "google",
        url: "https://google.com/search?q={{QUERY}}",
    },
    bing: {
        name: "Bing",
        id: "bing",
        url: "https://bing.com/search?q={{QUERY}}",
    },
};

export const DEFAULT_WEB_SEARCH_PROVIDER = "yahoo";
