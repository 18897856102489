/**
 * Autocomplete settings and utilities
 */

import memoize from "lodash.memoize";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { SEARCH_API_INDEXER_URL } from "../../urls";
import { FILTER_IMAGE_URLS } from "../../Helper";

const ONE_MINUTE_MS = 60 * 1000;

// We will setup this to allow a call ever 10 minutes
const clearCache = debounce(
    () => suggestionProvider.cache.clear(),
    10 * ONE_MINUTE_MS
);

const unescape = htmlStr => {
    htmlStr = htmlStr.replace(/&lt;/g, "<");
    htmlStr = htmlStr.replace(/&gt;/g, ">");
    htmlStr = htmlStr.replace(/&quot;/g, '"');
    htmlStr = htmlStr.replace(/&#39;/g, "'");
    htmlStr = htmlStr.replace(/&amp;/g, "&");
    return htmlStr;
};

const suggestionProvider = memoize(async term => {
    clearCache(); // Make sure the cache doesnt get too big
    const qs = {
        q: term,
        perPage: 20,
        pageNo: 0,
        field: "resultEntity",
        out_fields: ["recipe_quality"],
        filters: {
            resultImage: { not: FILTER_IMAGE_URLS },
        },
    };
    const res = await fetch(`${SEARCH_API_INDEXER_URL}/complete`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(qs),
    });
    if (!res.ok) {
        return await res.json().catch(e => ({
            error: "Bad Response",
            query: term,
            results: [],
        }));
    }
    const data = await res.json();
    if (data.main && data.main.records) {
        // filter by recipe quality, take max 10 items
        data.main.records = data.main.records
            .filter(i => i.recipe_quality === "good")
            .slice(0, 10);
        data.main.records.map(i => (i.text = unescape(i.text)));
        data.main.records.map(i => (i.resultEntity = unescape(i.resultEntity)));
    }
    return {
        ...data.main,
    };
});

export { suggestionProvider };
