<template>
    <!--main wrapper-->
    <div class="serp-wrapper">
        <!-- Bookmark Popup  -->
        <!--header wrapper-->
        <div class="header-wrapper">
            <!--header-->
            <div class="serp-header">
                <div class="serp-logo"><img :src="branding.SerpLogo" /></div>
                <div class="innerSearch">
                    <!--nav-->
                    <div id="serp-nav">
                        <div
                            id="job-btn"
                            class="serp-nav-item"
                            :class="{ active: productSearch }"
                            @click="toProductSearch()"
                        >
                            {{ branding.SearchLabel }}
                        </div>
                        <div
                            id="web-btn"
                            class="serp-nav-item"
                            v-if="branding.ShowWebSearch && !isFirefox"
                            :class="{ active: !this.productSearch }"
                            @click="toWebSearch()"
                        >
                            Web Search
                        </div>
                    </div>
                    <!--End of nav-->
                    <div class="thisSearch" v-show="productSearch">
                        <div class="search pad-right">
                            <auto-complete-input
                                v-model="query"
                                ref="queryInput"
                                :placeholder="branding.AutocompletePlaceholder"
                                @selected="freshQuerySearch()"
                                @return="freshQuerySearch()"
                            />
                            <div class="error" v-if="queryError">
                                This field is required, please enter
                            </div>
                        </div>
                        <button id="btn-search" @click="freshQuerySearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="webSearch" v-show="!productSearch">
                        <div class="search web">
                            <input
                                ref="webSearch"
                                id=""
                                placeholder="Search the web"
                                v-model="wQuery"
                                @keydown.enter="startWebSearch()"
                            />
                            <div class="s-icon">
                                <i class="fa fa-globe" aria-hidden="true"></i>
                            </div>
                            <div class="error" v-if="queryError">
                                This field is required, please enter
                            </div>
                        </div>
                        <button id="btn-search" @click="startWebSearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!--End of Header-->
        </div>
        <!--End of header wrapper-->
        <!--New FirstRun-->
        <div class="topFirstrun" v-if="whichPop">
            <div class="close" @click="onCloseHelp()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <first-run
                v-if="showFirstRun"
                :which="whichPop"
                :firstRunQuery="firstRunQuery"
                :firstRunCount="firstRunCount"
                :onNext="onNextPop"
            />
            <!--begin of notification for privacy policy-->
            <div
                class="udpl"
                id="udpl"
                v-if="showFirstRun && !hidePrivacyModal"
                :which="whichPop"
            >
                <div class="udplb">
                    Your privacy is important to us. Please take a moment to
                    familiarize yourself with our
                    <a
                        :href="`https://${branding.RootDomain}/privacy-policy/`"
                        target="_blank"
                        >Privacy Policy.</a
                    ><br />
                    It also contains information for people who live in the
                    State of California, or European Economic Area.
                </div>
                <button @click="hidePrivacyModal = true">OK</button>
            </div>
            <!--end of notification for privacy policy-->
        </div>
        <!--End of New FirstRun-->
        <!--serp results-->
        <div class="bodyWrapper">
            <div class="titleTop">
                <div
                    class="noResultsFound"
                    v-if="(!hasSearchResults || badSearch) && searchCompleted"
                >
                    Oops! We dont have
                    <span
                        ><span v-if="enteredQuery && enteredQuery != '*'"
                            >"{{ enteredQuery }}"</span
                        >
                        <span v-if="this.enteredResultCategory1.length">{{
                            this.enteredResultCategory1.join(", ")
                        }}</span> </span
                    >. We are working on getting recipes like
                    <span
                        ><span v-if="enteredQuery && enteredQuery != '*'"
                            >"{{ enteredQuery }}"</span
                        >
                        <span v-if="this.enteredResultCategory1.length">{{
                            this.enteredResultCategory1.join(", ")
                        }}</span> </span
                    >. Please check back soon.
                </div>
                <div class="serp-stats" v-show="hasSearchResults && !badSearch">
                    Found {{ totalRecords }} total
                    <span v-if="isFiltered"> filtered </span> results
                    <span v-if="searchTerm && !emptySearch && !whichPop"
                        >for <span class="kw">{{ searchTerm }}</span></span
                    >
                    <span v-for="category in resultCategory1"
                        ><span
                            class="kw tagFilter"
                            @click="removeFilter(1, category)"
                            >{{ category }}</span
                        ></span
                    >
                    <div
                        class="clearAll"
                        @click="clearSearch"
                        v-show="hasSearchResults && isFiltered"
                    >
                        Clear Filters
                    </div>
                </div>
            </div>
            <div
                class="emptySearchTitle"
                id="empty-search-title"
                v-if="badSearch"
            >
                You Might Also Like:
            </div>
            <div class="serp-results">
                <!--Right col -->
                <div class="rightCol">
                    <div class="rightColInner">
                        <div
                            class="menuItem"
                            v-for="menu in helper.MENUS"
                            @click=""
                        >
                            <p>{{ menu.name }}</p>
                            <!--DropDown Menu-->
                            <div class="dropBox">
                                <div class="dropBoxInner">
                                    <div class="dropLeftCol">
                                        <div class="dTitle">
                                            {{ menu.name }}
                                        </div>
                                    </div>
                                    <div class="dropRightCol">
                                        <div class="flexContainer">
                                            <div
                                                class="filterOptions"
                                                v-if="
                                                    submenu.model ==
                                                        'resultCategory1'
                                                "
                                                v-for="submenu in menu.items"
                                                @click="
                                                    e =>
                                                        e.target.classList.toggle(
                                                            'active'
                                                        )
                                                "
                                            >
                                                <input
                                                    type="checkbox"
                                                    :id="submenu.value"
                                                    :value="submenu.value"
                                                    v-model="resultCategory1"
                                                    @change="doSearch()"
                                                /><label :for="submenu.value">{{
                                                    submenu.value
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End of Right col-->
                <!--Left col-->
                <div class="serp-results-left">
                    <!-----First Run Add on------>
                    <!-----End of First Run Add on------>
                    <div v-show="hasSearchResults" class="masonry">
                        <!--results-->
                        <div
                            class="srl has-image"
                            v-for="(result, index) in records"
                            :key="index"
                            @click.prevent="
                                openUrl(
                                    getRecipeUrl(result),
                                    true,
                                    false,
                                    false
                                )
                            "
                        >
                            <div class="srl-container">
                                <div v-if="result.resultFree" class="freeTag">
                                    Free with Ads
                                </div>
                                <div class="srl-img-container">
                                    <div
                                        class="imgLoading"
                                        :id="`imgLoading_${index}`"
                                    >
                                        <img
                                            src="/assets/images/loading-white.svg"
                                        />
                                    </div>
                                    <div class="srl-img">
                                        <img
                                            :src="resultImage(result)"
                                            @error="replaceImg($event)"
                                            @load="onImageLoad(index)"
                                        />
                                    </div>
                                </div>
                                <div class="srl-info" :docID="result.id">
                                    <div class="srl-info-inner">
                                        <h3 class="srl-title">
                                            <a
                                                :href="getRecipeUrl(result)"
                                                target="_blank"
                                                >{{ result.resultEntity }}</a
                                            >
                                        </h3>
                                        <div
                                            v-show="result.description"
                                            class="srl-description"
                                        >
                                            {{ result.description[0] }}
                                        </div>
                                        <div
                                            class="cookTime"
                                            v-show="
                                                helper.getFormattedIsoDuration(
                                                    result.resultCookTime
                                                ) ||
                                                    helper.getFormattedIsoDuration(
                                                        result.resultTotalTime
                                                    )
                                            "
                                        >
                                            <b>Cook Time:</b>
                                            {{
                                                helper.getFormattedIsoDuration(
                                                    result.resultCookTime
                                                ) ||
                                                    helper.getFormattedIsoDuration(
                                                        result.resultTotalTime
                                                    )
                                            }}
                                        </div>
                                        <div
                                            class="cal"
                                            v-show="result.resultCalories"
                                        >
                                            <b>Calories:</b>
                                            {{ result.resultCalories }}
                                        </div>
                                        <div
                                            v-show="result.resultCategory1"
                                            class="srl-cat"
                                        >
                                            <strong>Tags:</strong
                                            >{{ resultCategories(result) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end of results-->
                    </div>
                    <!--Browse Panel-->
                    <!--End of left col-->
                </div>
            </div>
            <!---Pagination---->
            <div class="paginationContainer">
                <pagination
                    v-show="hasSearchResults"
                    :pageSize.sync="perPageNo"
                    :currentPage.sync="currentPage"
                    :maxPages.sync="totalPages"
                    @changePage="onChangePage"
                    :key="totalPages"
                />
            </div>
        </div>
        <!--End of resultes-->

        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter">
                    <img :src="branding.FooterLogo" /><span
                        ><span id="brandDetails"
                            ><i class="fa fa-copyright" aria-hidden="true"></i>
                            {{ branding.Trademark }}</span
                        >
                    </span>
                </div>
                <ul>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/terms-of-service/`
                            "
                            >Terms of Service
                        </a>
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/privacy-policy/`
                            "
                            >Privacy</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/contact-us/`"
                            >Contact Us</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/uninstall/`"
                            >Uninstall</a
                        >
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/privacy-policy/#cali-consumer`
                            "
                            target="_blank"
                            >Do Not Sell My Personal Information</a
                        >
                    </li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
    <!-- End of main wrapper -->
</template>
<script>
import bp from "babel-polyfill";
import queryString from "query-string";
import "whatwg-fetch";
import Humanize from "humanize-plus";
import { getSetting, setSetting, getGuid } from "../storage";
import {
    WEB_SEARCH_URL,
    openUrl,
    SEARCH_API_INDEXER_URL,
    API_INDEXER_GROUP,
    FRESHY_RECIPE_URL,
    FRESHY_LINK_TRACKING,
} from "../urls";
import FirstRun from "../components/HomeFirstRun.vue";
import AutoCompleteInput from "../components/AutoCompleteInput.vue";
import Pagination from "../components/Pagination.vue";
import {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
    altSearchProvidersUrls,
} from "../branding";
import helper, { FILTER_IMAGE_URLS } from "../Helper";
import { documentReady, isFirefox } from "../common";
import "../analytics";

function validatePop(name) {
    switch (name) {
        case "intro":
        case "i":
            return "intro";
        case "t":
        case "tutorial":
            return "tutorial";
        case "it":
            return "intro-tutorial";
    }

    return false;
}

export default {
    name: "serp",
    components: {
        "first-run": FirstRun,
        "auto-complete-input": AutoCompleteInput,
        pagination: Pagination,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            openUrl,
            recentSearches: [],
            productSearch: true,
            records: [],
            searchCompleted: false,
            query: qs.q || "",
            resultCategory1: qs.ingredients ? [qs.ingredients] : [],
            enteredQuery: null,
            enteredResultCategory1: [],
            wQuery: "",
            queryError: false,
            searchTerm: "",
            guid: qs.guid,
            productEngine: qs.engine,
            whichPop: validatePop(qs.pop) || false,
            branding: getBranding(),
            isEnd: false,
            totalRecords: 0,
            totalPages: 0,
            currentPage: 0,
            perPageNo: 20,
            currentRequest: null,
            trigger: 150,
            isFetching: false,
            firstLoad: true,
            badSearch: false,
            emptySearch: false,
            firstRunQuery: null,
            firstRunCount: null,
            helper,
            adSenseContent: "",
            hidePrivacyModal: false,
            isFirefox,
        };
    },
    mounted() {
        documentReady.then(async () => {
            this.recentSearches =
                JSON.parse(getSetting("recentSearches")) || [];

            try {
                this.guid = await getGuid();
            } catch (e) {
                // no extension or guid query paraneter
            }
            this.$refs.queryInput.focus();
            await this.handleSearchUrl();

            this.firstRunQuery = this.query;
            this.firstRunCount = this.totalRecords;
            this.firstLoad = false;
        });
    },
    async created() {
        window.onpopstate = () => {
            this.handleSearchUrl();
        };
    },
    methods: {
        getRecipeUrl(recipe) {
            const url = new URL(`${FRESHY_RECIPE_URL}/${recipe.uuid}`);
            Object.keys(FRESHY_LINK_TRACKING).forEach(k => {
                url.searchParams.set(k, FRESHY_LINK_TRACKING[k]);
            });
            url.searchParams.set(
                "utm_source",
                this.branding.ProductName.replace(/ /g, "")
            );
            return url.toString();
        },
        onImageLoad(index) {
            const loadIcon = document.getElementById(`imgLoading_${index}`);
            if (loadIcon) loadIcon.style.display = "none";
        },
        resetAllFilters(clear) {
            const qs = {
                ...queryString.parse(window.location.search),
            };
            this.resultCategory1 =
                !clear && qs.ingredients ? [qs.ingredients] : [];
        },
        resultCategories(result) {
            const cat = [];
            if (result.resultCategory1) cat.push(result.resultCategory1);
            return cat.join(", ");
        },
        resultImage(result) {
            if (result) {
                const { resultImageAsset } = result;
                const backupImage = result.resultImage;
                try {
                    return (
                        new URL(resultImageAsset).hostname && resultImageAsset
                    );
                } catch (e) {
                    return backupImage || "/assets/images/no-image.jpg";
                }
            } else return null;
        },
        replaceImg(e) {
            if (this.branding && this.branding.Icon) {
                e.target.src = this.branding.Icon;
            }
        },
        async getNextResults(page) {
            let check = true;
            if (page < this.totalPages && !this.isFetching) {
                this.currentPage = page;

                const newRecords = await this.makeNextCall();

                if (newRecords) {
                    this.records = newRecords;
                }
                if (this.currentPage >= this.totalPages - 1) {
                    this.isEnd = true;
                }
                check = true;
            } else check = false;

            this.isFetching = false;
            return check;
        },
        async makeNextCall() {
            this.isFetching = true;
            const body = this.currentRequest;
            body.pageNo = this.currentPage;

            const recordsReq = await fetch(`${SEARCH_API_INDEXER_URL}/search`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });
            if (recordsReq.ok) {
                const recordsRes = await recordsReq.json();
                window.postMessage("searchCompleted", "*");
                return recordsRes.main.records;
            }
            this.isFetching = false;
        },
        async startWebSearch() {
            const searchParams = {
                k: this.wQuery,
                guid: this.guid,
            };
            await openUrl(
                `${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`,
                false
            );
        },
        validateSearch(withFocus) {
            const { query } = this;
            let errs = false;
            if (this.query == "") {
                errs = errs || {};
                errs.query = "This field is required, Please enter";
            }
            this.queryError = errs;
            if (withFocus) {
                if (errs.query) this.$refs.queryInput.focus();
            }
            return errs === false;
        },
        addToBrowserHistory() {
            let qs = {
                ...queryString.parse(window.location.search),
            };
            if (qs.pop) delete qs.pop;
            if (this.enteredQuery) {
                qs.q = this.enteredQuery;
            }

            // Add guid if there is one.
            if (this.guid) {
                qs.guid = this.guid;
            }
            qs = queryString.stringify(qs);
            const newUrl = `${this.baseUrl}?${qs}`;
            // Set Page Title
            const title = this.enteredQuery === "*" ? "" : this.enteredQuery;
            if (title)
                document.title = this.branding.ProductName
                    ? `${this.branding.ProductName} - ${title}`
                    : `${title}`;

            if (!this.firstLoad)
                window.history.pushState(
                    { query: this.enteredQuery },
                    this.enteredQuery,
                    newUrl
                );
        },
        async freshQuerySearch(fromUrl = false) {
            // close first run
            if (!fromUrl) this.whichPop = false;

            this.badSearch = false;
            this.resetAllFilters();
            this.enteredQuery = this.query;
            if (!fromUrl && this.enteredQuery && this.enteredQuery != "*") {
                this.addToBrowserHistory();
                if (this.enteredQuery && this.enteredQuery != "*") {
                    this.recentSearches = this.recentSearches.filter(
                        x => x !== this.enteredQuery
                    );
                    if (this.recentSearches.unshift(this.enteredQuery) > 10) {
                        this.recentSearches.pop();
                    }
                    setSetting(
                        "recentSearches",
                        JSON.stringify(this.recentSearches)
                    );
                }
            }
            await this.doSearch(fromUrl);
        },
        async doSearch() {
            window.scrollTo(0, 0);
            const term = this.enteredQuery || "*";

            this.records = [];
            this.isEnd = false;
            this.emptySearch = false;
            this.currentPage = 0;

            this.searchCompleted = false;

            const body = {
                group: API_INDEXER_GROUP,
                q: term || "",
                facets: {
                    recipedata: true, // required to have recipeData
                    url: true,
                    uuid: true,
                },
            };
            if (this.filterList) body.filters = this.filterList;

            if (this.perPageNo) body.perPage = this.perPageNo;
            if (this.currentPage) body.pageNo = this.currentPage;

            const recordsReq = await fetch(`${SEARCH_API_INDEXER_URL}/search`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            if (recordsReq.ok) {
                const recordsRes = await recordsReq.json();

                if (recordsRes && recordsRes.main) {
                    this.tags = [];
                    if (term && recordsRes.main.records) {
                        this.totalPages = recordsRes.main.numPages;
                        this.totalRecords = recordsRes.main.total;
                        this.records = recordsRes.main.records;
                        // save current body for infinity scroll / pagination
                        this.currentRequest = body;

                        this.searchTerm = "";
                        if (this.enteredQuery && this.enteredQuery != "*")
                            this.searchTerm = this.enteredQuery;
                        else this.searchTerm = "";
                    }
                }
                this.searchCompleted = true;
                window.postMessage("searchCompleted", "*");
            }
        },
        toWebSearch() {
            this.productSearch = false;
            this.$nextTick(() => this.$refs.webSearch.focus());
        },
        toProductSearch() {
            this.productSearch = true;
            this.$nextTick(() => {
                this.$refs.queryInput.focus();
            });
        },
        async handleSearchUrl() {
            const qs = queryString.parse(window.location.search);
            const { q, g } = qs;

            if (q && q != "*") {
                this.query = q;
                document.title = this.branding.ProductName
                    ? `${this.branding.ProductName} - ${this.query}`
                    : `${this.query}`;
            } else
                document.title = this.branding.ProductName
                    ? this.branding.ProductName
                    : "";
            await this.freshQuerySearch(true);
        },

        onNextPop(tag) {
            this.whichPop = validatePop(tag) || false;
        },
        onCloseHelp() {
            switch (this.whichPop) {
                case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
            }
            this.whichPop = false;
            this.addToBrowserHistory();
        },
        async onChangePage(page) {
            if (page != this.currentPage) {
                await this.getNextResults(page);
            }
        },
        async clearSearch(e) {
            if (!this.isFiltered) return;
            this.resetAllFilters(true);
            await this.handleSearchUrl();
        },
        async removeFilter(opt, value) {
            switch (opt) {
                case 1:
                    this.resultCategory1.splice(
                        this.resultCategory1.indexOf(value),
                        1
                    );
                    break;
            }
            await this.doSearch();
        },
    },
    computed: {
        baseUrl() {
            return window.location.href.split("?")[0];
        },
        hasSearchResults() {
            return this.records && this.records.length;
        },
        isFiltered() {
            return this.resultCategory1.length;
        },
        filterList() {
            const output = {};

            // filter categories
            if (this.resultCategory1 && this.resultCategory1.length) {
                output.resultCategory1 = this.resultCategory1;
            }
            this.enteredResultCategory1 = this.resultCategory1;
            // ADD OTHER FILTERS HERE

            output.resultImage = { not: FILTER_IMAGE_URLS };

            output.recipedata_available = { term: true };
            // recipe quality filter
            output.recipe_quality = { term: "good" };

            if (output) return output;
            return false;
        },
        showFirstRun() {
            if (this.query) {
                if (this.totalRecords) return true;
                return false;
            }
            return true;
        },
    },
};
</script>
<style>
@import "./css/home.css";
</style>
