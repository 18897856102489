const defaultBranding = {
    // type of product - eg, "forms"
    ProductType: "PrintRecipes",
    // menu text to select product search tab - eg, "Forms Search"
    SearchLabel: "Search Recipes",
    // meta keyword specified in manifest (might not be the same as product type) - eg, "forms"
    MetaSearchKeyword: "recipe",
    // example searches on first run - eg, "Taxes, Immigration, US"
    SearchExample: "cookies, breakfast",
    // eg, "Search form titles or tags"
    AutocompletePlaceholder: "Search For Recipes",
    // eg, "Search our database for forms by typing in a form query in the search box above."
    IntroLI1: "Get started with searching your recipes",
    // eg, "You can also browse our forms database by 'tag' located on the left side menu of the page."
    IntroLI2: "",
    FirstRunSettings: false,
    adSlot: "2046194165",
    adClient: "ca-pub-4659644490955201",
    ShowWebSearch: true,
};

const windowBranding = window.branding || {};

const PRODUCT_SEARCH_PROVIDERS = {
    printrecipes: {
        name: "Print recipes",
        id: "printrecipes",
        isDefault: true,
    },
};

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(
        ([key, value]) => !value.isDefault
    );
    return altProviders.map(([key, value]) => ({
        name: value.name,
        url: value.url.replace("{{QUERY}}", query),
    }));
};

const DEFAULT_PRODUCT_SEARCH_PROVIDER =
    PRODUCT_SEARCH_PROVIDERS.printrecipes.id;

function getBranding() {
    return {
        ...defaultBranding,
        ...windowBranding,
    };
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
};
