<template>
    <ul
        v-if="maxPages > 0 && maxPages > pageSize"
        class="pagination modal-1"
        :style="ulStyles"
    >
        <li
            class="page-item first"
            :class="{ disabled: activePage === 1 }"
            :style="liStyles"
        >
            <a class="page-link" @click="setPage(1)" :style="aStyles">{{
                labels.first
            }}</a>
        </li>
        <li
            class="page-item previous"
            :class="{ disabled: activePage === 1 }"
            :style="liStyles"
        >
            <a
                class="page-link"
                @click="setPage(activePage == 1 ? activePage : activePage - 1)"
                :style="aStyles"
                >{{ labels.previous }}</a
            >
        </li>
        <li
            v-for="page in pageList(activePage)"
            :key="page"
            class="page-item page-number"
            :style="liStyles"
        >
            <a
                class="page-link"
                @click="setPage(page)"
                :class="{ active: activePage === page }"
                :style="aStyles"
                >{{ page }}</a
            >
        </li>
        <li
            class="page-item next"
            :class="{ disabled: activePage === maxPages }"
            :style="liStyles"
        >
            <a
                class="page-link"
                @click="
                    setPage(activePage == maxPages ? maxPages : activePage + 1)
                "
                :style="aStyles"
                >{{ labels.next }}</a
            >
        </li>
        <li
            class="page-item last"
            :class="{ disabled: activePage === maxPages }"
            :style="liStyles"
        >
            <a class="page-link" @click="setPage(maxPages)" :style="aStyles">{{
                labels.last
            }}</a>
        </li>
    </ul>
</template>

<script>
const defaultLabels = {
    first: "First",
    last: "Last",
    previous: "«",
    next: "»",
};

const defaultStyles = {
    ul: {
        margin: 0,
        padding: 0,
        display: "inline-block",
    },
    li: {
        listStyle: "none",
        display: "inline",
        textAlign: "center",
    },
    a: {
        cursor: "pointer",
        padding: "6px 12px",
        display: "block",
        float: "left",
    },
};

export default {
    name: "pagination",
    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
        initialPage: {
            type: Number,
            default: 1,
        },
        pageSize: {
            type: Number,
            default: 20,
        },
        paginationSize: {
            type: Number,
            default: 10,
        },
        maxPages: {
            type: Number,
            default: 10,
        },
        labels: {
            type: Object,
            default: () => defaultLabels,
        },
        styles: {
            type: Object,
        },
        disableDefaultStyles: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ulStyles: {},
            liStyles: {},
            aStyles: {},
            activePage: this.currentPage + 1,
        };
    },
    created() {
        if (!this.$listeners.changePage) {
            throw 'Missing required event listener: "changePage"';
        }
        // set default styles unless disabled
        if (!this.disableDefaultStyles) {
            this.ulStyles = defaultStyles.ul;
            this.liStyles = defaultStyles.li;
            this.aStyles = defaultStyles.a;
        }

        // merge custom styles with default styles
        if (this.styles) {
            this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
            this.liStyles = { ...this.liStyles, ...this.styles.li };
            this.aStyles = { ...this.aStyles, ...this.styles.a };
        }
        // set page if records array isn't empty
        if (this.currentPage > 1) this.activePage = this.currentPage;
        this.setPage(this.activePage);
    },
    methods: {
        setPage(page) {
            // emit change page event to parent component
            this.activePage = page;
            this.$emit("changePage", page - 1);
            window.scrollTo(0, 0);
        },
        pageList(page) {
            if (page + this.pageSize < this.maxPages) {
                const from = page;
                const to = page + this.paginationSize - 1;
                return Array(to - from + 1)
                    .fill()
                    .map((_, idx) => from + idx);
            }
            const from = page - this.paginationSize;
            const to = page;
            return Array(to - from + 1)
                .fill()
                .map((_, idx) => from + idx);
        },
    },
};
</script>
